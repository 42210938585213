// extracted by mini-css-extract-plugin
export var header_2 = "index-module--header_2--TUnWy";
export var header_3 = "index-module--header_3--Hs5CJ";
export var u_center = "index-module--u_center--Mqa9M";
export var u_right = "index-module--u_right--eJKTS";
export var uLeft = "index-module--u-left--ocvaO";
export var u_margin_right = "index-module--u_margin_right--Zgbt1";
export var u_margin_left = "index-module--u_margin_left--4Kwzm";
export var u_margin_bottom_small = "index-module--u_margin_bottom_small--XFnum";
export var u_margin_bottom_medium = "index-module--u_margin_bottom_medium--RWjFc";
export var u_margin_bottom_big = "index-module--u_margin_bottom_big--GZs+Y";
export var u_margin_bottom_huge = "index-module--u_margin_bottom_huge--ZLXmz";
export var about = "index-module--about--NTz6a";
export var about_container = "index-module--about_container--xAiYg";
export var about_content = "index-module--about_content--wGCVy";
export var about_text = "index-module--about_text--d0MY6";
export var about_link = "index-module--about_link--lfJZK";
export var about_imgContainer = "index-module--about_imgContainer--qBq9l";
export var about_figure = "index-module--about_figure--M28+s";
export var about_figure_caption = "index-module--about_figure_caption--j5oel";
export var about_figure_caption_link = "index-module--about_figure_caption_link--JjRDe";
export var about_img = "index-module--about_img--od+Z5";
export var compositions = "index-module--compositions--xnGuc";
export var compositions_container = "index-module--compositions_container--5J9eW";
export var compositions_header_2 = "index-module--compositions_header_2--bPEW-";
export var compositions_img_1_container = "index-module--compositions_img_1_container--mma5o";
export var compositions_img_2_container = "index-module--compositions_img_2_container--lRUUc";
export var compositions_img_1 = "index-module--compositions_img_1--UvlaX";
export var compositions_img_2 = "index-module--compositions_img_2--X5yag";
export var compositions_group = "index-module--compositions_group--gDWvG";
export var compositions_group_header = "index-module--compositions_group_header--3T3FJ";
export var compositions_ul = "index-module--compositions_ul--viShy";
export var compositions_li = "index-module--compositions_li--8jBbw";
export var compositions_a = "index-module--compositions_a--zWIWC";
export var contact = "index-module--contact--s4dZg";
export var contact_header = "index-module--contact_header--RUtPg";
export var contact_email = "index-module--contact_email--nRqYm";
export var contact_email_alert = "index-module--contact_email_alert--49lQM";
export var showAnimation = "index-module--showAnimation--a22Ny";
export var fadeInOut = "index-module--fadeInOut---iTFr";
export var hideInput = "index-module--hideInput--r4BOC";
export var displayNone = "index-module--displayNone--KCiAp";
export var slideFromLeft = "index-module--slideFromLeft--PxQCc";