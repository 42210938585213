// extracted by mini-css-extract-plugin
export var header_2 = "nav-module--header_2--4SY3S";
export var header_3 = "nav-module--header_3--rwoxG";
export var u_center = "nav-module--u_center--X99-8";
export var u_right = "nav-module--u_right--3t8Zz";
export var uLeft = "nav-module--u-left--qTAfk";
export var u_margin_right = "nav-module--u_margin_right--rEOmP";
export var u_margin_left = "nav-module--u_margin_left--XQdEH";
export var u_margin_bottom_small = "nav-module--u_margin_bottom_small--4Pje9";
export var u_margin_bottom_medium = "nav-module--u_margin_bottom_medium--scR3B";
export var u_margin_bottom_big = "nav-module--u_margin_bottom_big--iIkik";
export var u_margin_bottom_huge = "nav-module--u_margin_bottom_huge--FDPe9";
export var nav = "nav-module--nav--Rg+KB";
export var contact = "nav-module--contact--CTkXa";
export var slideFromLeft = "nav-module--slideFromLeft--+GFVR";
export var fadeInOut = "nav-module--fadeInOut--s0yQS";