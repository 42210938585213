// extracted by mini-css-extract-plugin
export var header_2 = "header-module--header_2--74Yal";
export var header_3 = "header-module--header_3--1Aker";
export var u_center = "header-module--u_center--we8k8";
export var u_right = "header-module--u_right--jUu47";
export var uLeft = "header-module--u-left--g21aM";
export var u_margin_right = "header-module--u_margin_right--lnjJL";
export var u_margin_left = "header-module--u_margin_left--JxF+i";
export var u_margin_bottom_small = "header-module--u_margin_bottom_small--oxog5";
export var u_margin_bottom_medium = "header-module--u_margin_bottom_medium--VTLUc";
export var u_margin_bottom_big = "header-module--u_margin_bottom_big--2UXH3";
export var u_margin_bottom_huge = "header-module--u_margin_bottom_huge--HK7vZ";
export var headerSection = "header-module--headerSection--7QP3p";
export var container = "header-module--container--HbbyE";
export var headerPrimary = "header-module--headerPrimary--WPAkX";
export var slideFromLeft = "header-module--slideFromLeft--VsyY4";
export var intro = "header-module--intro--brY03";
export var intro_img = "header-module--intro_img--JMSvj";
export var intro_content = "header-module--intro_content--pGA34";
export var intro_content_shortBio = "header-module--intro_content_shortBio--ExGon";
export var fadeInOut = "header-module--fadeInOut--usHLM";