// extracted by mini-css-extract-plugin
export var header_2 = "footer-module--header_2--jKJOT";
export var header_3 = "footer-module--header_3--Y4gkW";
export var u_center = "footer-module--u_center--7GkEf";
export var u_right = "footer-module--u_right--oISY2";
export var uLeft = "footer-module--u-left--uzFtj";
export var u_margin_right = "footer-module--u_margin_right--RwI3+";
export var u_margin_left = "footer-module--u_margin_left--MX6Mk";
export var u_margin_bottom_small = "footer-module--u_margin_bottom_small--UVbvF";
export var u_margin_bottom_medium = "footer-module--u_margin_bottom_medium--Uikm2";
export var u_margin_bottom_big = "footer-module--u_margin_bottom_big--f7hsa";
export var u_margin_bottom_huge = "footer-module--u_margin_bottom_huge--Ozeto";
export var footer = "footer-module--footer--9+n7+";
export var mainContainer = "footer-module--mainContainer--c6qMw";
export var nav = "footer-module--nav--LVbLY";
export var ul = "footer-module--ul--iATVj";
export var li = "footer-module--li--0-ktp";
export var a = "footer-module--a--oZPlV";
export var line = "footer-module--line--fevMx";
export var copyright = "footer-module--copyright--Lb9rm";
export var author = "footer-module--author--HBp2c";
export var hideakiLink = "footer-module--hideakiLink--cOPD7";
export var slideFromLeft = "footer-module--slideFromLeft--bXNoc";
export var fadeInOut = "footer-module--fadeInOut--1XlEF";